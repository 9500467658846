/**
 * Created by biao on 4/21/15.
 */
(function(RS){
    "use strict";
    RS.Views.ChangePasswordView = Backbone.View.extend({
        initialize:function(options){
          if(options.user == 'me'){
              this.models = new RS.Models.User({'userid': 'me'});
              this.models.once('sync',function(){
                  this.models.set('userid','me');
              },this);
              this.models.fetch();
          }
            this.form = this.$el.find('form.form').parsley();
        },
        events:{
            'click .btn-save':'submit',
            'click .btn-cancel':'cancel',
        },
        submit:function(e){
            e.preventDefault();

            if(!this.form.validate() ){
                console.log('input not correct !');
                return;
            }
            else {
                var pwd = this.$el.find('input.input-password').val();
                var confirm_pwd = this.$el.find('input.input-confirm-password').val();
                if(pwd != confirm_pwd){
                    console.log('password is not equal to confirmed password !');
                    return;
                }
                else{
                    var input = {new_password:pwd};
                    this.models.once('sync',function(){
                        console.log('save success');
                        window.location.assign('/users/me');
                    });
                    this.models.save(input,{wait:true,patch:true});
                }
            }
        },
        cancel:function(e){
            window.location.assign('/users/me');
        }
    });
})(window.Redso);


