(function(RS){
  "use strict";

  RS.Views.RegisterView = Backbone.View.extend({
    initialize: function(options){
      this.form = this.$el.find("form.form").parsley();
    },

    events: {
      'click #register-facebook': 'facebookClicked',
      'submit form.form': 'registerClicked',
    },

    registerClicked: function(e){
      e.preventDefault();

      if (!this.form.validate())
        return;

      var email = this.$el.find('input[name=email]').val();
      var password = this.$el.find('input[name=password]').val();
      var input = {
        email: email,
        password: password,
      };
      var user = new RS.Models.User();
      user.on('sync', function(e){
        RS.alert({message:'Please check your email for the way to activate your account.'});
        RS.login();
      },this);
      user.save(input, {wait:true});
    },
    registerByFacebook: function(fat) {
      var user = new RS.Models.User();
      user.on('sync', function(e){
        //auto login
        var accessToken = new RS.Models.AccessToken();
        accessToken.on('sync', function(e){
          //window.location.reload();
          window.location.assign('/');
        }, this);
        accessToken.fetch({data:{facebook_access_token:fat}, wait: true});
      },this);
      user.save({facebook_access_token:fat}, {wait:true});
    },
    facebookClicked: function(e) {
      var _this = this;
      e.preventDefault();
      FB.getLoginStatus(function(response) {
        //console.log(response);
        if (response.status === 'connected') {
          _this.registerByFacebook(response.authResponse.accessToken);
        } else {
          FB.login(function(response) {
            if (response.authResponse) {
              _this.registerByFacebook(response.authResponse.accessToken);
            } else {
              // failed
            }
          }, {scope: 'email'});
        }
      });
    },

    show: function(){
      this.$el.show();
    },
    hide: function(){
      this.$el.hide();
    },
  });
})(window.Redso);
