(function(RS){
  'use strict';

  // A collection is actually an array of model. 
  RS.Collections.Users = Backbone.Collection.extend({

    // this collection is actually an array of User
    // If the collection is going to store mixed model, this `model' could be a function and return 
    // the suitable Model class
    model: RS.Models.User,

    // The path used for fetch() and other methods.
    url: '/api/users',

    // Parse the user list return by the server or force to parse an array during .set()
    parse: function(response, options) {
      // Might need to pass extra pagination data to other model.
      // This is necessary to do.
      if (options.pageData){
        options.pageData.set( _.omit(response, 'results') );
      }

      // I don't care the other data at this point.
      return response.results;
    },
  });

})(window.Redso);
