(function (RS){
  "use strict";
  
  RS.Views.PaginatorView = Backbone.View.extend({
    initialize: function(options){
      this.template = _.template( $('#template-paginator').html(), {'variable': 'data'} );
      this.pageSize = 5;
      this.perPage = 5;
      this.count = 0;
      this.model = options.model;
      this.model.on('sync', function(e){
        this.render();
      }, this);
      this.changeToPage(1);
    },

    events: {
      'click a[data-page]': 'changePage',
    },

    'changePage': function(e){
      e.preventDefault();
      var goToPage = parseInt($(e.currentTarget).attr('data-page'), 10);
      this.changeToPage(goToPage);
    },

    // Update the UI and internal states to reflect the change of model
    'changeToPage': function(page){
      this.page = page; 
      var data = {hits: this.pageSize, cursor: 'offset:'+((this.page-1)*this.pageSize)};
      this.model.fetch({data: data, reset: true, paginator: this});
      //this.render();
      //this.trigger('changePage');
    },

    render: function(){
      var perPage = this.perPage;
      var pages = Math.ceil(this.count/this.pageSize);
      var startPage, endPage;
      startPage = this.page-(perPage+1)/2+1;
      if (startPage < 1)
        startPage = 1;
      endPage = startPage + perPage;
      if (endPage > pages + 1) {
        endPage = pages + 1;
        startPage = endPage - perPage;
        if (startPage < 1)
          startPage = 1;
      }
      console.log([startPage,endPage,this.page,pages]);
      if (pages > 1){
        this.$el.html( this.template( {
          startPage: startPage,
          endPage: endPage,
          pages: pages,
          currentPage: this.page,
        } ) );
      }
      // console.log(this.$el.html());
      this.delegateEvents( this.events );
      return this;
    },
  });
})(window.Redso);
