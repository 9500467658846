(function(RS){
  "use strict";

  RS.Views.ChatroomView = Backbone.View.extend({
    initialize: function(options){
        if (RS.user && !RS.user.isNew()) {
            this.user = RS.user;
        }else {
            //RS.login({'dismissable':false});
            window.location.assign('/login');
            return;
        }
        this.chatuserid = options.userid;
        this.existreceiver = false;
        this.initial = true;
        this.objDiv = $('.message-view')[0];

        this.num_unread_messages = 0;

        this.currentID = null;

        this.messageTemplate = _.template( $('#template-message').html(), {variable: 'data'} );
        this.messagePageData = new RS.Models.PageData();
        this.messages = new RS.Collections.Messages({'pageData':this.messagePageData});
        this.messages.firstclick = true;
        this.messages.on('sync', function(e) {
          this.renderChatroom();  //write this function
        }, this);

        this.chatPageData = new RS.Models.PageData();//add template
        this.chatlistTemplate = _.template( $('#template-chatlist').html(), {variable: 'data'} );
        this.chatrooms = new RS.Collections.Chatrooms({'pageData':this.chatPageData});
        this.chatrooms.on('sync', function(e) {
          this.renderChatlist();  //write this function
        }, this);
        this.chatrooms.fetch({data: {hits: 1000}, wait: true});

        this.MessageNotificationsThread = new RS.Collections.MessageNotifications();
    },

    events: {
      'click .chatlist-row': 'chatlistClicked',
      'click #submit_msg': 'submitNewMessage',
      'click #loadMore': 'loadMoreMessage',
    },

    messageNotifications: function(e){
      var that = this;
      this.MessageNotificationsThread.on('sync', function(){
        if (this.MessageNotificationsThread.pageData.get('num_unread_messages')==this.num_unread_messages) {
          // console.log('no update!');
        } else {
          // console.log('update chatlist!');
          this.num_unread_messages = this.MessageNotificationsThread.pageData.get('num_unread_messages');
          this.chatPageData = new RS.Models.PageData();//add template
          this.chatrooms.pageData = this.chatPageData;
          this.chatrooms.fetch({data: {hits: 1000}, wait: true});
        }
        this.MessageNotificationsThread.timer = setTimeout(function(){
          that.MessageNotificationsThread.fetch({data:{unread_count_only:1}, wait:true});
        },this.MessageNotificationsThread.timeout);
      }, this);
      this.MessageNotificationsThread.timeout = 2000;
      this.MessageNotificationsThread.fetch({data:{unread_count_only:1}, wait:true});
    },

    chatlistClicked: function(e){
      e.preventDefault();
      //console.log($(e.currentTarget).attr('data-chatid'));
      if($(e.currentTarget).not('.active')){
        $(e.currentTarget).addClass('active').siblings('.active').removeClass('active');
      }
      this.showMsg();
      this.messages.firstclick = true;
      this.messages.pageData = new RS.Models.PageData();
      this.messages.chatroom = this.chatrooms.at($(e.currentTarget).attr('data-chatid')).toJSON();
      this.messages.receiver_id = this.messages.chatroom.members[0].userid;
      this.messages.fetch({data: {hits: 10}, wait: true});
    },

    renderChatlist: function(){
      var that = this;
      if (this.chatrooms.length>0) {
        if (this.initial) {
          this.chatrooms.at(0).set('num_unread', 0);
        }

        this.chatroomsJSON = this.chatrooms.toJSON();
        //console.log(this.chatroomsJSON[0].members[0].userid);
        if (this.initial) {
          if (this.chatuserid == 'chats') {
            this.messages.receiver_id = this.chatroomsJSON[0].members[0].userid;
            //this.chatroomsJSON[0].num_unread = 0;
            this.showList();
          }
          else {
            this.existreceiver = false;
            this.messages.receiver_id = this.chatuserid;
            this.showMsg();
          }
          this.messageNotifications();
          this.initial = false;
        }

        this.chatroomsJSON.forEach(function (chat) {
          if (chat.members[0].userid == that.messages.receiver_id) {
            chat.num_unread = 0;
            that.messages.chatroom = chat;
            that.messages.pageData = new RS.Models.PageData();
            that.messages.fetch({data: {hits: 10}, wait: true});
            that.existreceiver = true;
          }
        });

        if (!this.existreceiver) {
          this.setNewChatroom();
        }
        else{
          this.$el.find('.chatlist-div').html(
              this.chatlistTemplate({
                chats: this.chatroomsJSON,
              }));
          this.$el.find('.chatlist-div [data-userid ='+ that.messages.receiver_id +']').addClass('active').siblings('.active').removeClass('active');
        }
      }
      else{
        if(this.initial && this.chatuserid !== undefined && this.chatuserid !== 'chats' ){
          this.setNewChatroom();
          this.initial = false;
          this.showMsg();
        }
        else{
          this.$el.find('.chatlist-div').html('<p class="font-green-dark topic-title text-center">暫時沒有任何聊天記錄!</p>');
          this.showList();

        }

      }
      return this;
    },

    setNewChatroom:function(){
      this.newChatter = new RS.Models.User({userid:this.chatuserid});
      this.newChatter.fetch();
      this.newChatter.on('sync',function(e){
        this.chatrooms.add({
          chatroom_id:function(){
            if(Number(this.user.userid) < Number(this.chatuserid))
            {
              return this.user.userid + ':' + this.chatuserid;
            }
            return this.chatuserid + ':' + this.user.userid;
          },
          members:[this.newChatter.toJSON()],
          num_unread:0,
          last_message:'',
        },{at: 0},{merge: true});
        this.$el.find('.chatlist-div').html(
            this.chatlistTemplate({
              chats:this.chatrooms.toJSON(),
            }));
        this.$el.find('.chatlist-div .chatlist-row').removeClass('active').first('.chatlist-row').addClass('active');
        this.messages.chatroom = this.chatrooms.toJSON()[0];
        this.messages.pageData = new RS.Models.PageData();
        this.messages.receiver_id = this.chatuserid;
        this.messages.fetch({data: {hits: 10}, wait: true});
      },this);
    },

    renderChatroom: function(){
      this.messagesJSON = this.messages.toJSON();
      if (this.messages.length>0) {
        this.$el.find('.message-div').html(
          this.messageTemplate({
            messages:this.messagesJSON,
            chatroom:this.messages.chatroom,
            more:this.messages.pageData.get('more'),
          })
        );
        // console.log(this.messages.pageData.get('more'));
        if(this.messages.firstclick)
          this.$el.find('.message-div').scrollTop(this.$el.find('.message-div')[0].scrollHeight);
        this.messages.firstclick = false;
      }
      else{
        this.$el.find('.message-div').html('<div class="message-user visible-xs">'+ this.messages.chatroom.members[0].name +'</div>'+
        '<p class="font-green-dark topic-title text-center">暫時沒有任何聊天記錄!</p>');
      }
      return this;
    },

    loadMoreMessage: function(){
      this.messages.fetch({data: {hits: 10, cursor: this.messages.pageData.get('cursor')}, wait: true, remove: false});
      return this;
    },

    submitNewMessage: function(){
      $content = this.$el.find('#content');
      if($content.val()!==""){
        this.message = $content.val();
        //console.log(message);
        var inputs = {
          'message' : this.message,
        };

        var newMessage = new this.messages.model({'receiver_id':this.messages.receiver_id});
        newMessage.once('sync', function(){
          // prepend the new model to the collection
          this.messages.unshift(newMessage);
          this.messages.firstclick = true;
          this.renderChatroom();

          //console.log(this.chatrooms.at(0).get('chatroom_id'));
          //this.chatroomsJSON = this.chatrooms.toJSON();
          var arrayLength = this.chatrooms.length;
          for (var i = 0; i < arrayLength; i++) {
            if(this.messages.chatroom.chatroom_id==this.chatrooms.at(i).get('chatroom_id')){
              //console.log(this.chatrooms.at(i));
              this.chatrooms.at(i).set("last_message", this.message);
              this.chatrooms.at(i).set("mtime", moment());
              this.chatrooms.unshift(this.chatrooms.remove(this.chatrooms.at(i)));
              break;
            }
          }
          // this.chatrooms.chatPageData = new RS.Models.PageData();
          // this.chatrooms.fetch({data: {hits: 1000}, wait: true});
          this.$el.find('.chatlist-div').html(
              this.chatlistTemplate({
                chats: this.chatrooms.toJSON(),
              }));
          this.$el.find('.chatlist-div [data-userid ='+ this.messages.receiver_id +']').addClass('active').siblings('.active').removeClass('active');
        }, this);
        newMessage.save(inputs, {wait: true});
        $content.val('').focus();
      }
      return this;
    },

    showList:function(){
      var $target1 = this.$el.find('.chatlist-view');
      var $target2 = this.$el.find('.message-view');
      if($target1.is('.hidden-xs')){
        $target1.removeClass('hidden-xs');
      }
      if($target1.not('.hidden-xs')) {
        $target2.addClass('hidden-xs');
      }
      this.$el.find('p.topic-title.mobile-list').hide();
      this.$el.find('p.topic-title.mobile-msg').show();
    },

    showMsg:function(){
      var $target1 = this.$el.find('.message-view');
      var $target2 = this.$el.find('.chatlist-view');
      if($target1.is('.hidden-xs')){
        $target1.removeClass('hidden-xs');
      }
      if($target1.not('.hidden-xs')) {
        $target2.addClass('hidden-xs');
      }
      this.$el.find('p.topic-title.mobile-list').show();
      this.$el.find('p.topic-title.mobile-msg').hide();
    },

    show: function(){
      this.$el.show();
    },
    hide: function(){
      this.$el.hide();
    },
  });
})(window.Redso);
