(function(RS){
  "use strict";

  RS.Models.Redso = Backbone.Model.extend({
    initialize: function(options){
      // Called during the model is instantizing (E.g. new RS.Models.User({name: 'dave', ...});)
      this.on('error', function(options, resp){
        //todo
        if (resp.responseText)
          RS.alert({message: resp.responseText});
        else
          RS.alert({message: resp.status + ' ' + resp.statusText});
      }, this);
    },

    // Called when the data is fetched from the server. Could be called by model.set()
    // Might create attributes that are not on the server.
    // For example, convert unix timestamp (string) to moment object.
    parse: function(response, options) {
      // response.ctime = moment(response.ctime);
      return response;
    },
  });
  
  // A dummy page data
  RS.Models.PageData = Backbone.Model.extend({
  });

})(window.Redso);
