(function(RS){
  "use strict";

  RS.Models.User = RS.Models.Redso.extend({
    urlRoot: '/api/users',    // define the base URL for building the CRUD paths.
    idAttribute: 'userid',    // define the unique ID of this model. Usually the ID in the DB.
    logout: function(){
      var token = new RS.Models.AccessToken({
        token: '',
      });
      token.isNew = function(){ return false; }.bind(this);
      token.destroy({
        success: function(){
          window.location.assign('/');
        },
      });
    },
  });

  RS.Models.AccessToken = RS.Models.Redso.extend({
    urlRoot: '/api/tokens/access',
    idAttribute: 'token',
  });

  RS.Models.ForgetPasswordToken = RS.Models.Redso.extend({
    urlRoot: '/api/tokens/forget',
    idAttribute: 'token',
  });

})(window.Redso);
