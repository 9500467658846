(function(RS){
  'use strict';

  // A collection is actually an array of model. 
  RS.Collections.News = Backbone.Collection.extend({

    // this collection is actually an array of User
    // If the collection is going to store mixed model, this `model' could be a function and return 
    // the suitable Model class
    model: RS.Models.News,

    // The path used for fetch() and other methods.
    url: '/api/news',

    // Parse the user list return by the server or force to parse an array during .set()
    parse: function(response, options) {
      // Might need to pass extra pagination data to other model.
      // This is necessary to do.
      console.log(options);
      if (options.paginator != undefined) {
        if (response.num_results != undefined)
          options.paginator.count = response.num_results;
      }
      console.log(options);
      
      // I don't care the other data at this point.
      return response.results;
    },
  });

})(window.Redso);
