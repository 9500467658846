/**
 * Created by biao on 4/8/15.
 */
(function(RS){
  "use strict";

  RS.Views.UserView = Backbone.View.extend({
    tagName: 'div',
    className: 'container',
    template: _.template($('#template-user-detail').html(), {variable: 'data'} ),
    render: function(){
      this.$el.html( this.template( this.model.toJSON() ) );
      return this;
    },

    getModel: function(){
      return this.model ? this.model : (this.model = new RS.Models.User());
    },

    show: function(id){
      id = id || purl().segment().pop();
      var model = this.getModel();
      model.set({
        userid: id,
      });

      model.once('sync', this.render, this);
      model.fetch();
    },
  });

  RS.Views.UserListItemView = Backbone.View.extend({
    tagName: 'div',
    className: 'row',

    template: _.template( $('#template-user-list-item').html() ),
    render: function(){
      this.$el.html( this.template( this.model.toJSON() ) );
      return this;
    },
  });

  RS.Views.UsersView = Backbone.View.extend({
    subviewClass: RS.Views.UserListItemView,

    initialize: function(options){
      if (!this.collection){
        this.collection = new RS.Collections.Users();
      }

      this.$spinner = this.$el.find('.spinner-container');
      this.$moreButton = this.$el.find('.more-button-container');

      this.pageData = new Backbone.Model();

      this.collection.once('sync', this.renderItems, this);
      this.collection.on('request', this.showLoading, this);
      this.collection.fetch({
        pageData: this.pageData,
      });
    },

    events: {
      'click [data-action=load-more]': 'loadMoreUsers',
    },

    loadMoreUsers: function(e){
      this.collection.fetch({
        pageData: this.pageData,
        reset: false,
        add: true,
        merge: true,
        remove: false,
      });
    },

    renderItems: function(){
      this.hideLoading();
      if (this.pageData.get('more')){
        this.showLoadMoreButton();
      } else {
        this.hideLoadMoreButton();
      }
      this.subviews = this.collection.map(function(user, i){
        return new this.subviewClass({
          model: user,
        });
      }, this);

      var htmls = _.map(this.subviews, function(sv, i){
        return sv.render().el;
      }, this);

      return this.$el.find('.items').html( htmls );
    },

    render: function(){

    },

    showLoadMoreButton: function(){
      this.$moreButton.show();
    },

    hideLoadMoreButton: function(){
      this.$moreButton.hide();
    },

    showLoading: function(){
      this.$spinner.show();
    },

    hideLoading: function(){
      this.$spinner.hide();
    },
  });
})(window.Redso);
