(function(RS){
  'use strict';

  RS.Collections.Chatrooms = Backbone.Collection.extend({
    model: RS.Models.Chatroom,
    url: '/api/users/me/messages',
    idAttribute:'chatroom_id',
    initialize: function(options){
      this.pageData = options.pageData;
    },
    parse: function(response, options) {
      if (this.pageData) {
        this.pageData.set( _.omit(response, 'chatrooms') );
      }

      return response.chatrooms;
    },
  });

  RS.Collections.Messages = Backbone.Collection.extend({
    model: RS.Models.Message,
    url: function(){
      return '/api/users/'+this.receiver_id +'/messages';
    },
    initialize: function(options){
      this.receiver_id = options.receiver_id;
      //this.receiver_pic = options.receiver_pic;
    },
    parse: function(response, options) {
      if (this.pageData) {
        this.pageData.set( _.omit(response, 'messages') );
      }
      return response.messages;
    },
  });

  RS.Collections.MessageNotifications = Backbone.Collection.extend({
    model: RS.Models.MessageNotification,
    url: '/api/users/me/messages',
    initialize: function(options){
      this.pageData = new RS.Models.PageData();
    },
    parse: function(response, options) {
      this.pageData.set( _.omit(response, 'notifications') );
      return response.notifications;
    },
  });

})(window.Redso);
