(function(RS){
  "use strict";

  RS.Models.Chatroom = RS.Models.Redso.extend({});

  RS.Models.Message = RS.Models.Redso.extend({
    urlRoot: function(){
      return '/api/users/'+this.receiver_id +'/messages';
    },
    initialize: function(options){
      this.receiver_id = options.receiver_id;
    },
  });

  RS.Models.MessageNotification = RS.Models.Redso.extend({});

})(window.Redso);
