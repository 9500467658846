(function(RS){
  "use strict";

  RS.Views.TopNavbarView = Backbone.View.extend({
    initialize: function(){
      if (this.model && this.model.get("userid")){
        this.renderUserInfo();
      }
    },

    events: {
      'click [data-action=logout]': 'logout',
    },

    logout: function(e){
      window.Redso.user.logout();     
    },

    renderUserInfo: function(){
      this.$el.find('[data-action=register], [data-action=login]').hide();
      this.$el.find('[data-action=editprofile], [data-action=logout]').show();
      this.$el.find('[data-action=editprofile] span.username').text( this.model.get('name') );
    },
  });

})(window.Redso);
