(function(RS){
  "use strict";

  RS.Views.BaseView = Backbone.View.extend({
    el: 'html',

    initialize: function(options){

      this.load_fb_sdk(window.fbAppId);

      RS.logged_in = function() {
      };
      RS.logged_out = function() {
      };
      RS.login = function(options) {
      };

      RS.alert = function(options) {
        $('#alert-modal .alert-message').html(options.message);
        if (options.dismissable==false) {
          $('#alert-modal .close').hide();
        } else {
          $('#alert-modal .close').show();
        }
        $('#alert-modal').modal();
      };

      //test login
      if (RS.user) {
        RS.logged_in();
      } else {
        RS.logged_out();
      }

    },

    load_fb_sdk: function(appId, fbVersion){
      fbVersion = fbVersion || 'v2.2';
      window.fbAsyncInit = function() {
        FB.init({
          appId      : appId,
          cookie     : true,
          xfbml      : true,
          version    : fbVersion,
        });
        
        // Trigger an event so that other views can know when the FB SDK is loaded.
        this.trigger('fb.init');
      }.bind(this);

      (function(d, s, id){
         var js, fjs = d.getElementsByTagName(s)[0];
         if (d.getElementById(id)) {return;}
         js = d.createElement(s); js.id = id;
         js.src = "//connect.facebook.net/en_US/sdk.js";
         fjs.parentNode.insertBefore(js, fjs);
       }(document, 'script', 'facebook-jssdk'));
    },

    events: {
    },

  });
})(window.Redso);
