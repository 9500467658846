(function(RS){
  "use strict";

  RS.Views.ProfileView = Backbone.View.extend({
    initialize: function(options){
      this.profileTemplate = _.template($("#template-profile").html(), {variable: 'user'});
      this.editProfileTemplate = _.template($("#template-edit-profile").html(), {variable: 'me'});
      this.itemPostTemplate = _.template($("#template-post").html(),{variable:'data'});
      this.postsTemplate = _.template($("#template-posts").html(), {variable: 'data'});

      var _this = this;
      this.pointsView = new window.Redso.Views.PointsView({
        el: '#points-container',
      });
      this.$el.on('click', "a.points", function(){
        _this.hide();
        _this.pointsView.show();
      });

      if (typeof(options.user)=='string' && options.user.indexOf('me') >= 0) {
        this.isMe = true;
        if (RS.user) {
          this.user = RS.user;
          if (options.user == 'editme'){
            this.editProfile();
          } else if (options.user == 'mepoints'){
            this.hide();
            this.pointsView.show();
          }
        } else {
          RS.login({'dismissable':false});
          return;
        }
      } else {
        this.isMe = false;
        this.user = options.user;
      }
      this.renderProfile();
      this.pageData = new RS.Models.PageData();
      this.posts = new RS.Collections.UserPosts({'userid':options.user=='me'?'me':this.user.userid, 'pageData':this.pageData});
      this.posts.on('sync', function(e) {
        this.renderPosts();
      }, this);
      this.posts.fetch({data: {hits: 6}, wait: true});
      
      (function(_this){
        $(window).scroll(function () {
         if ($(window).scrollTop() >= $(document).height() - $(window).height() - 10) {
            if (_this.pageData.get('more') && ResponsiveBootstrapToolkit.is('xs')) {
              _this.$el.find('.btn-more').trigger('click');
            }
           }
         });
        }
      )(this);
    },

    events: {
      'click .btn-edit-profile': 'editProfileClicked',
      'click .btn-save': 'saveClicked',
      'click .btn-cancel': 'cancelClicked',
      'click .btn-write-post': 'writePostClicked',
      'click .btn-message':'sendMsgClicked',
      'click .btn-facebook': 'facebookClicked',
      'click .btn-more': 'moreClicked',
      'change .input-profile-photo': 'profilePhotoChanged',
      'change .input-cover-photo': 'coverPhotoChanged',
      'click .btn-edit-photo': 'editPhotoClicked',
      'click .btn-edit-profile-photo' : 'editProfilePhotoClicked',
    },
    editPhotoClicked: function(e) {
      this.$el.find('.input-cover-photo').trigger('click');
    },
    editProfilePhotoClicked: function(e) {
      this.$el.find('.input-profile-photo').trigger('click');
    },
    writePostClicked: function(e){
      window.location.assign('/post-editor');
    },
    sendMsgClicked:function(e){
      window.location.assign('/chatroom/' + this.user.userid);
    },
    connectWithFacebook: function(fat) {
      this.me.once('sync', function(e) {
        this.me.set('userid','me');
        this.renderEditProfile();
      }, this);
      this.me.save({facebook_access_token:fat}, {wait: true, patch: true});
    },
    facebookClicked: function(e) {
      var _this = this;
      e.preventDefault();
      FB.getLoginStatus(function(response) {
        console.log(response);
        if (response.status === 'connected') {
          _this.connectWithFacebook(response.authResponse.accessToken);
        } else {
          FB.login(function(response) {
            if (response.authResponse) {
              _this.connectWithFacebook(response.authResponse.accessToken);
            } else {
              // failed
            }
          }, {scope: 'email'});
        }
      });
    },

    moreClicked: function(e) {
      this.$el.find('.profile-row .posts .btn-more').hide();
      this.$el.find('.profile-row .posts .img-loading').show();
      this.posts.fetch({data: {hits: 6, cursor: this.pageData.get('cursor')}, wait: true});
    },
    renderPosts: function(){
      this.$el.find('.profile-row .posts .img-loading').hide();
      var posts = this.posts.toJSON();
      this.$el.find('.profile-row .posts .posts-row').append(this.postsTemplate({itemTemplate:this.itemPostTemplate,posts:posts}));
      if (this.pageData.get('more')) {
        this.$el.find('.profile-row .posts .btn-more').show();
      } else {
        this.$el.find('.profile-row .posts .btn-more').hide();
      }
      this.delegateEvents( this.events );
      return this;
    },

    renderProfile: function(){
      if (this.user.type == 'vip' && this.user.cover_photo) {
        this.$el.find('.cover-photo').html(
          (this.isMe?'<div class="container down-level"><a href="#" class="up-level tr-btn-default btn-edit-photo">更改封面圖片</a></div>':'') +
          '<i class="gradient-white-right up-level top left visible-md visible-lg"></i><img class="cover-img" src="'+
          this.user.cover_photo.url+'" /><input class="form-control input-cover-photo" type="file" name="cover_photo" style="display: none;"/>' +
          '<i class="gradient-white-left up-level top right visible-md visible-lg"></i>');
      }
      this.$el.find('.profile-row .profile').html( this.profileTemplate(this.user) );

      this.delegateEvents( this.events );
      return this;
    },

    saveClicked: function(e){
      e.preventDefault();

      if (!this.editProfileForm.validate())
        return;

      var name = this.$el.find('.edit-profile-row input[name=name]').val();
      var biography = this.$el.find('.edit-profile-row textarea[name=biography]').val();
      var profile_photo = this.$el.find('.edit-profile-row .input-profile-photo[data-image]').attr('data-image');
      var cover_photo = this.$el.find('.edit-profile-row .input-cover-photo[data-image]').attr('data-image');
      //var newsletter = this.$el.find('.edit-profile-row input[name=newsletter_subscribed]').is(":checked");
      var input = {
        name: name,
        biography: biography,
        //newsletter_subscribed: newsletter,
      };
      if (profile_photo) input.profile_photo = profile_photo;
      if (cover_photo) input.cover_photo = cover_photo;
      this.me.once('sync', function(e) {
        this.me.set('userid','me');
        this.renderEditProfile();
        this.$el.find('.profile-row').show();
        this.$el.find('.edit-profile-row').hide();
      }, this);
      this.me.save(input, {wait: true, patch: true});
    },
    cancelClicked: function(e) {
      e.preventDefault();
      this.$el.find('.profile-row').show();
      this.$el.find('.edit-profile-row').hide();
    },
    editProfileClicked: function(e) {
      e.preventDefault();
      this.editProfile();
    },
    editProfile:function(){
      this.me = new RS.Models.User({'userid': 'me'});
      this.me.once('sync', function() {
        this.$el.find('.profile-row').hide();
        this.$el.find('.cover-photo').hide();
        this.$el.find('.edit-profile-row').show();
        this.me.set('userid','me');
        this.renderEditProfile();
      }, this);
      this.me.fetch({wait: true});
    },
    renderEditProfile: function(){
      this.$el.find('.edit-profile-row').html( this.editProfileTemplate( this.me.toJSON() ) );
      this.editProfileForm = this.$el.find(".edit-profile-row form.form").parsley();
      this.delegateEvents( this.events );
      return this;
    },
    coverPhotoChanged: function(e){
      e.preventDefault();
      var reader = new FileReader();
      reader.onload = (function(_this) {
        return function(e) {
          _this.$el.find(".cover-photo .cover-img").attr('src', e.target.result);
          _this.$el.find(".input-cover-photo").attr('data-image', e.target.result.replace(/[^,]*,/, ''));

          var input = {
            cover_photo: $('.input-cover-photo[data-image]').attr('data-image'),
          };
          var me = new RS.Models.User({'userid': 'me'});
          me.once('sync', function(e) {
            _this.user = this.toJSON();
            _this.renderProfile();
          }, me);
          _this.$el.find(".cover-photo").html("<div class='cover-photo-loading'><img src='/images/loading_3.gif' /></div>");
          me.save(input, {wait: true, patch: true});
        };
      })(this);
      reader.readAsDataURL(e.currentTarget.files[0]);
      e.currentTarget.files = [];
    },
    profilePhotoChanged: function(e){
      e.preventDefault();
      var reader = new FileReader();
      reader.onload = (function(_this) {
        return function(e) {
          _this.$el.find(".edit-profile-row .img-profile-photo").attr('src', e.target.result);
          _this.$el.find(".edit-profile-row .input-profile-photo").attr('data-image', e.target.result.replace(/[^,]*,/, ''));
        };
      })(this);
      reader.readAsDataURL(e.currentTarget.files[0]);
      e.currentTarget.files = [];
    },

    show: function(){
      this.$el.show();
    },
    hide: function(){
      this.$el.hide();
    },
  });

  RS.Views.PointsView = Backbone.View.extend({
    initialize: function(options) {
      this.template = _.template($("#template-points").html(), {variable: 'points'});
      this.collection = new RS.Collections.UserPoints();
      this.collection.on('sync', function(){
        this.render();
      }, this);

      (function(_this){
        $(window).scroll(function () {
         if ($(window).scrollTop() >= $(document).height() - $(window).height() - 10) {
            if (_this.collection.pageData.get('more') && ResponsiveBootstrapToolkit.is('xs')) {
              _this.$el.find('.btn-more').trigger('click');
            }
           }
         });
        }
      )(this);
    },

    events: {
      'click .btn-more': 'moreClicked',
      'click .back': 'backClicked',
    },

    backClicked: function(e){
      this.hide();
      $('#profile-container').show();
    },

    moreClicked: function(e){
      this.$el.find('.img-loading').show();
      this.collection.fetch({data:{hits:20, cursor:this.collection.pageData.get('cursor')}, wait: true});
    },

    render: function(){
      this.$el.find('.img-loading').hide();
      if (this.collection.pageData.get('more'))
        this.$el.find('.btn-more').show();
      else
        this.$el.find('.btn-more').hide();
      this.$el.find('.points').html( this.template(this.collection.toJSON()) );
      return this;
    },

    show: function(){
      this.$el.find('.img-loading').show();
      this.collection.fetch({data:{hits:20}, wait: true});
      this.$el.show();
    },
    hide: function(){
      this.$el.hide();
    },

  });

})(window.Redso);