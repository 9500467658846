(function(RS){
  "use strict";

  RS.Views.LoginView = Backbone.View.extend({
    initialize: function(options){
      this.loginForm = this.$el.find("#login-form").parsley();
      this.forgetPasswordForm = this.$el.find("#forget-password-form").parsley();
    },

    events: {
      'click .btn-login': 'loginClicked',
      'click #login-facebook': 'facebookClicked',
      'submit #login-form': 'loginClicked',
      'submit #forget-password-form': 'forgetPasswordClicked',  //for forget password
    },
    loginClicked: function(e){
      e.preventDefault();

      if (!this.loginForm.validate())
        return;

      var email = this.loginForm.$element.find('input[name=email]').val();
      var password = this.loginForm.$element.find('input[name=password]').val();
      var accessToken = new RS.Models.AccessToken();
      accessToken.on('sync', function(e){
        //RS.logged_in();
        window.location.assign('/');
      }, this);
      accessToken.fetch({data:{email:email, password:password}, wait: true});
    },
    loginByFacebook: function(fat) {
      var accessToken = new RS.Models.AccessToken();
      accessToken.off('error');
      accessToken.on('error', function(e){
        //redirect to registration page
        window.location.assign('/register');
      }, this);
      accessToken.on('sync', function(e){
        //RS.logged_in();
        //window.location.reload();
        window.location.assign('/');
      }, this);
      accessToken.fetch({data:{facebook_access_token:fat}, wait: true});
    },
    facebookClicked: function(e) {
      var _this = this;
      e.preventDefault();
      FB.getLoginStatus(function(response) {
        //console.log(response);
        if (response.status === 'connected') {
          _this.loginByFacebook(response.authResponse.accessToken);
        } else {
          FB.login(function(response) {
            if (response.authResponse) {
              _this.loginByFacebook(response.authResponse.accessToken);
            } else {
              // failed
              //RS.alert({message:'Incorrect username or password.'});
              //redirect to registration page
              window.location.assign('/register');
            }
          }, {scope: 'email'});
        }
      });
    },

    forgetPasswordClicked: function(e){
      e.preventDefault();

      if (!this.forgetPasswordForm.validate())
        return;

      var email = this.forgetPasswordForm.$element.find('input[name=email]').val();
      var forgetPasswordToken = new RS.Models.ForgetPasswordToken();
      forgetPasswordToken.on('sync', function(e){
        RS.alert({message: 'Please check your email for the way to change your password.'});
      }, this);
      forgetPasswordToken.fetch({data:{email:email}, wait: true});
    },

    show: function(){
      this.$el.show();
    },
    hide: function(){
      this.$el.hide();
    },
  });
})(window.Redso);
