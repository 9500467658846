(function(RS){
  "use strict";

  RS.Views.NewsListView = Backbone.View.extend({
    initialize: function(options){
      this.collection = new RS.Collections.News();
      this.template = _.template( $('#template-news-list-item').html(), {'variable': 'data'} );
      this.collection.on('sync', function(options){
        this.render();
      }, this);
      this.collection.once('sync', function(options){
        if (window.location.hash){
          this.newsDetailView.render(window.location.hash.substr(1));
        }
        else
          this.$el.find('.news-item').first().click();
      }, this);

      this.newsDetailView = new RS.Views.NewsDetailView({
        el: this.$el.find('.news-detail'),
        collection: this.collection,
      });
    },

    events: {
      // 'click thead button.create': 'createClicked',
      // 'click button.edit': 'editClicked',
      'click div.news-item': 'showNews',
      //'click button.withhold': 'withholdClicked',
    },
    showNews: function(e){
      var id = $(e.currentTarget).attr('data-id');
      if (id){
        window.location.hash = id;
        this.newsDetailView.render(id);
        this.$el.find('.news-item').removeClass('active');
        $(e.currentTarget).addClass('active');
      }
    },
    // editClicked: function(e){
    //   this.$el.find('.news-table').hide();
    //   this.newsEditorView.show(this.collection.get($(e.currentTarget).attr('data-news-id')));
    // },
    // cancelClicked: function(){
    //   this.$el.find('thead  .edit-value').hide();
    //   this.$el.find('thead  .view-value').show();
    //  },
    // saveClicked: function(){
    //   var self = this;
    //   //date local to utc
    //   var event_start_local = this.$el.find('thead input[name=event_start]').val();
    //   if (moment(event_start_local).isValid('YYYY-MM-DD HH:mm:ss')){
    //     var event_start = moment.utc(moment(event_start_local,"YYYY-MM-DD HH:mm:ss")).format('YYYY-MM-DD HH:mm:ss');
    //   }
    //   var event_end_local = this.$el.find('thead input[name=event_end]').val();
    //   if (moment(event_end_local).isValid('YYYY-MM-DD HH:mm:ss')){
    //     var event_end = moment.utc(moment(event_end_local,"YYYY-MM-DD HH:mm:ss")).format('YYYY-MM-DD HH:mm:ss');
    //   }
    //   var inputs = {
    //     'etitle' : this.$el.find('thead input[name=etitle]').val(),
    //     'ctitle' : this.$el.find('thead input[name=ctitle]').val(),
    //     'econtent' : this.$el.find('thead input[name=econtent]').val(),
    //     'ccontent' : this.$el.find('thead input[name=ccontent]').val(),
    //     'evenue' : this.$el.find('thead input[name=evenue]').val(),
    //     'cvenue' : this.$el.find('thead input[name=cvenue]').val(),
    //     'event_start': event_start,
    //     'event_end': event_end,
    //     'location': this.$el.find('thead input[name=location]').val(),
    //   };

      //this.collection.create(inputs, {wait: true}); //dunno how to handle the error case for this call
     //  var aEvent = new this.collection.model();
     //  aEvent.once('sync', function(){
     //    // prepend the new model to the collection
     //    this.collection.unshift(aEvent);
     //    this.render();
     //  }, this);
     //   aEvent.save(inputs, {wait: true});
     //  this.$el.find('thead .edit-value').hide();
     //  this.$el.find('thead .view-value').show();
     // },


    render: function(){
      console.log(this.collection);
      this.$el.find('.news-list').html(this.collection.map(function(model){
        return this.template(model.toJSON());
      },this));
      // // console.log(this.$el.html());
      // this.delegateEvents( this.events );
      // return this;
    },

    // show: function(){
    //   this.$el.show();
    // },
    // hide: function(){
    //   this.$el.hide();
    // },
  });
  RS.Views.NewsDetailView = Backbone.View.extend({
    tagName: 'tr',

    initialize: function(options){
      this.el = options.el;
      this.colleciton = options.collection;
      this.template = _.template( $('#template-news-detail').html(), {'variable': 'data'} );
    },
    render: function(id){
      this.model = this.collection.get(id);
      if (this.model){
        console.log(this.model.toJSON());
        this.$el.html( this.template( this.model.toJSON() ) );
        // console.log(this.$el.html());
        this.delegateEvents( this.events );
        return this;
      } else {
        var self = this;
        this.model = new RS.Models.News({newsid: id});
        this.model.fetch().done(function(model, response, options){
          self.$el.html( self.template( model.news ) );
          self.delegateEvents( self.events );
          return self;
        });
      }
    },
  });
})(window.Redso);