/**
 * Created by biao on 4/8/15.
 */
(function(RS){
  "use strict";

  RS.Views.IndexView = Backbone.View.extend({
    initialize: function(options){
      this.newsList = new RS.Collections.News();
      this.newsList.on('sync', function(collection, resp, options){
        this.renderNews();
      }, this);
      this.newsList.fetch();
      this.newsItemTemplate = _.template($('#template-home-news-item').html(), {variable: 'data'});
    },

    renderNews: function(){
      var htmls = this.newsList.map(function(n){
        return this.newsItemTemplate({
          news: n.toJSON(),
        });
      }, this);
      this.$el.find('.row.news').html(htmls);
    },
  });
})(window.Redso);
